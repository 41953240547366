<template>
  <b-modal
    id="modal-change-avatar"
    title="Change Avatar"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col>
            <b-form-group
              label="Choose image for avatar"
            >
                <div class="dropbox_e">
                    <input
                        id="file"
                        type="file"
                        accept="image/jpeg, image/png"
                        model="file"
                        ref="file"
                        placeholder="select file"
                        @change="onFileChange"
                        class="input-file_e"
                    >
                    <div id="preview" class="mt-1">
                    <img
                        src="@/assets/fi_upload.png" class="icon"
                    />
                    </div>
                </div>
                <br/>
                <label class="text-upload">*Maximum file upload 10MB</label>
                <br>
                <label class="text-upload">**Format file should be image.jpeg / .png / .jpg</label>
                <br/>
                <div class="mt-2 text-upload">
                  <b-row v-if="file">
                    <b-col>
                      {{ file.name }}
                    </b-col>
                    <b-col align="right">
                    <img
                      v-if="file"
                      src="@/assets/Light.png"
                      v-on:click="cancelUpload"
                      class="icon"
                    />
                    </b-col>
                  </b-row>
                </div>
                <br/>
            </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      class="primary shadow"
      variant="warning"
      block
      @click="submitAvatar"
    >
      Save Change
    </b-button>
  </b-modal>
</template>

<script>

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    image: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    file: null,
    fileUploads: null,
    fileUploadPreview: null,
    selectedFile: null
  }),

  methods: {
    reloadPage() {
      window.location.reload()
    },
    onFileChange(e) {
      this.file = e.target.files[0]
      const size = parseFloat(this.file.size / (1024 * 1024)).toFixed(2)
      if (size > 10.00) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.file = null
      } else {
        var check = this.file.name.split('.').pop()
        const arrDocType = ['jpeg', 'png', 'jpg']
        if (arrDocType.includes(check.toLowerCase())) {
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
        } else {
          this.$bvToast.toast('Only image with jpeg, png, and jpg format allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
          this.file = null
        }
      }
    },
    cancelUpload() {
      this.file = null
    },
    async submitAvatar() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        this.$emit('set', this.file)
        this.$bvModal.hide('modal-change-avatar')
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
.dropbox_e {
  border: 2px solid #D3D3D3;
  text-align: right;
  cursor: pointer;
}
.input-file_e {
  margin-top: -3px;
  margin-left: 16px;
  left: 0;
  opacity: 0;
  width: 460px;
  height: 30px;
  position: absolute;
  cursor: pointer;
}

.icon {
  margin: -6px 3px 0px 0px;
  width:15px;
  height:15px;
}
</style>
