var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',[_c('validation-provider',{attrs:{"name":"avatar","rules":"required"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-avatar',{attrs:{"src":_vm.avatarImage,"rounded":"","size":"72px"}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticClass:"next merge",attrs:{"variant":"warning"},on:{"click":_vm.goToChangeAvatar}},[_vm._v("Change Avatar")]),_c('modal-change-avatar',{attrs:{"image":_vm.avatarImage},on:{"set":function (imageAvatar) { return _vm.changeAvatar(imageAvatar); }}})],1)],1)])],1)]),_c('br'),_c('b-card',[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('h5',[_vm._v("Personal Data")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"employee number","rules":"required|min:6|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employee Number *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"disabled":""},model:{value:(_vm.form.employeeNo),callback:function ($$v) {_vm.$set(_vm.form, "employeeNo", $$v)},expression:"form.employeeNo"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"full name","rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fullname *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your full name","trim":""},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"nick name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nickname *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your nick name","trim":""},model:{value:(_vm.form.nickName),callback:function ($$v) {_vm.$set(_vm.form, "nickName", $$v)},expression:"form.nickName"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"identity card","rules":"required|min:16|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Identity Card *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your identity card number","trim":""},model:{value:(_vm.form.identityCard),callback:function ($$v) {_vm.$set(_vm.form, "identityCard", $$v)},expression:"form.identityCard"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Gender *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"text","clearable":false,"options":_vm.genders,"placeholder":"Select gender"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"birth date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Birth Date *","invalid-feedback":errors[0]}},[_c('date-picker',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select birth date","type":"date","valueType":"YYYY-MM-DD","value":_vm.formatDate(_vm.form.birthDate),"format":"DD-MM-YYYY","editable":false,"clearable":false},model:{value:(_vm.form.birthDate),callback:function ($$v) {_vm.$set(_vm.form, "birthDate", $$v)},expression:"form.birthDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"birth place","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Birth Place *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your birth place","trim":""},model:{value:(_vm.form.birthPlace),callback:function ($$v) {_vm.$set(_vm.form, "birthPlace", $$v)},expression:"form.birthPlace"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"religion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Religion *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.religions,"clearable":false,"placeholder":"Select religion","reduce":function (ref) {
	var id = ref.id;

	return id;
}},model:{value:(_vm.form.religion),callback:function ($$v) {_vm.$set(_vm.form, "religion", $$v)},expression:"form.religion"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"NPWP","rules":"max:21"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NPWP","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your npwp number","trim":""},model:{value:(_vm.form.npwp),callback:function ($$v) {_vm.$set(_vm.form, "npwp", $$v)},expression:"form.npwp"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"vaccine number","rules":"min:21|max:21"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vaccine Number","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your vaccine number","trim":""},model:{value:(_vm.form.vaccineNumber),callback:function ($$v) {_vm.$set(_vm.form, "vaccineNumber", $$v)},expression:"form.vaccineNumber"}})],1)]}}])})],1)]),_c('hr'),_c('h5',[_vm._v("Employee Data")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"join date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Join Date *","invalid-feedback":errors[0]}},[_c('date-picker',{class:{ 'is-invalid': errors.length },attrs:{"type":"date","valueType":"YYYY-MM-DD","value":_vm.formatDate(_vm.form.joinDate),"format":"DD-MM-YYYY","editable":false,"clearable":false},model:{value:(_vm.form.joinDate),callback:function ($$v) {_vm.$set(_vm.form, "joinDate", $$v)},expression:"form.joinDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"resign date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Resign Date","invalid-feedback":errors[0]}},[_c('date-picker',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select resign date","type":"date","valueType":"YYYY-MM-DD","value":_vm.formatDate(_vm.form.resignDate),"format":"DD-MM-YYYY","editable":false,"clearable":false},model:{value:(_vm.form.resignDate),callback:function ($$v) {_vm.$set(_vm.form, "resignDate", $$v)},expression:"form.resignDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"text","options":_vm.positions,"clearable":false,"placeholder":"Select your position"},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Role","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"role","options":_vm.employeeRoles,"clearable":false,"placeholder":"Select your role"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"objective","rules":"min:1|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Objective *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your objective","trim":""},model:{value:(_vm.form.objective),callback:function ($$v) {_vm.$set(_vm.form, "objective", $$v)},expression:"form.objective"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"profile","rules":"min:1|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Profile *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your profile","trim":""},model:{value:(_vm.form.profile),callback:function ($$v) {_vm.$set(_vm.form, "profile", $$v)},expression:"form.profile"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"bank account number","rules":"required|numeric|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bank Account Number *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your bank account number","type":"text"},on:{"input":function($event){return _vm.restrictInput($event)}},model:{value:(_vm.form.bankAccNumber),callback:function ($$v) {_vm.$set(_vm.form, "bankAccNumber", $$v)},expression:"form.bankAccNumber"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"bank name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bank Name *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.bankNames,"clearable":false,"placeholder":"Select your bank name"},model:{value:(_vm.form.bank),callback:function ($$v) {_vm.$set(_vm.form, "bank", $$v)},expression:"form.bank"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"current salary","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Current Salary *","description":"Please input the nominal of current salary without punctuation. Example: 10000000","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Enter your current salary","trim":""},model:{value:(_vm.form.currentSalary),callback:function ($$v) {_vm.$set(_vm.form, "currentSalary", $$v)},expression:"form.currentSalary"}})],1)]}}])})],1)]),_c('hr'),_c('h5',[_vm._v("Address")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Address *","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your address","trim":"","rows":"5"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Province *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.provinces,"clearable":false,"placeholder":"Select province"},on:{"input":function($event){return _vm.fetchCity(_vm.form.province, true)}},model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"City *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.cities,"clearable":false,"placeholder":"Select city"},on:{"input":function($event){return _vm.fetchDistrict(_vm.form.city, true)}},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"District *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.districts,"clearable":false,"placeholder":"Select district"},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"postal code","rules":"required|numeric|min:5|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Postal Code *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your postal code","trim":""},model:{value:(_vm.form.postalCode),callback:function ($$v) {_vm.$set(_vm.form, "postalCode", $$v)},expression:"form.postalCode"}})],1)]}}])})],1)]),_c('hr'),_c('h5',[_vm._v("Knowledge")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"language"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Language *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","clearable":false,"options":_vm.languages,"placeholder":"Select language"},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"programming language"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Programming Language","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.programmingLanguages,"multiple":"","clearable":false,"placeholder":"Select programming language"},model:{value:(_vm.form.knowledge.programmingLanguage),callback:function ($$v) {_vm.$set(_vm.form.knowledge, "programmingLanguage", $$v)},expression:"form.knowledge.programmingLanguage"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"operating system"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Operating System *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","clearable":false,"options":_vm.operatingSystems,"placeholder":"Select operating system"},model:{value:(_vm.form.knowledge.operatingSystem),callback:function ($$v) {_vm.$set(_vm.form.knowledge, "operatingSystem", $$v)},expression:"form.knowledge.operatingSystem"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"framework"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Framework","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","clearable":false,"options":_vm.frameworks,"placeholder":"Select framework"},model:{value:(_vm.form.knowledge.framework),callback:function ($$v) {_vm.$set(_vm.form.knowledge, "framework", $$v)},expression:"form.knowledge.framework"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"database"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Database","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","clearable":false,"options":_vm.databases,"placeholder":"Select database"},model:{value:(_vm.form.knowledge.database),callback:function ($$v) {_vm.$set(_vm.form.knowledge, "database", $$v)},expression:"form.knowledge.database"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Platform","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","multiple":"","clearable":false,"options":_vm.platforms,"placeholder":"Select platform"},model:{value:(_vm.form.knowledge.platform),callback:function ($$v) {_vm.$set(_vm.form.knowledge, "platform", $$v)},expression:"form.knowledge.platform"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('validation-provider',{attrs:{"name":"development tools"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Development Tools","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","options":_vm.devTools,"multiple":"","clearable":false,"placeholder":"Select development tool"},model:{value:(_vm.form.knowledge.developmentTools),callback:function ($$v) {_vm.$set(_vm.form.knowledge, "developmentTools", $$v)},expression:"form.knowledge.developmentTools"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"others"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Others","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter others","trim":""},model:{value:(_vm.form.other),callback:function ($$v) {_vm.$set(_vm.form, "other", $$v)},expression:"form.other"}})],1)]}}])})],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('h5',[_vm._v("Contact")]),_c('validation-provider',{attrs:{"name":"email address","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticStyle:{"margin-top":"15px"},attrs:{"label":"Email Address *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your email address","ttpe":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"phone number","rules":"required|numeric|min:10|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone Number *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your phone number","type":"text"},on:{"input":function($event){return _vm.restrictInput($event, 'phoneNumber')}},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Emergency Contact Name","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Emergency Contact Name","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your emergency contact name","trim":""},model:{value:(_vm.form.emergencyContactName),callback:function ($$v) {_vm.$set(_vm.form, "emergencyContactName", $$v)},expression:"form.emergencyContactName"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Emergency Contact Number","rules":"required|numeric|min:10|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Emergency Contact Number*","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your emergency contact number","type":"text"},on:{"input":function($event){return _vm.restrictInput($event, 'emergencyContactNumber')}},model:{value:(_vm.form.emergencyContactNumber),callback:function ($$v) {_vm.$set(_vm.form, "emergencyContactNumber", $$v)},expression:"form.emergencyContactNumber"}})],1)]}}])})],1),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"d-flex"},[_c('h5',{staticClass:"mr-auto"},[_vm._v("Social Media")]),_c('b-button',{staticClass:"next merge justify-content-end ml-90",attrs:{"variant":"primary"},on:{"click":_vm.addFieldSocialMedia}},[_vm._v(" Add Social Media ")])],1),_c('div',{staticClass:"row"},[(_vm.form.socialMedia.length)?_c('div',{staticClass:"col-lg-6"},_vm._l((_vm.form.socialMedia),function(socialMedia,index){return _c('div',{key:index},[_c('validation-provider',{attrs:{"name":"social media","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("Username Social Media " + (index + 1)),"description":"ex: Username LinkendIn, Github, etc.","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter username of your social media","trim":""},model:{value:(_vm.form.socialMedia[index].username),callback:function ($$v) {_vm.$set(_vm.form.socialMedia[index], "username", $$v)},expression:"form.socialMedia[index].username"}})],1)]}}],null,true)})],1)}),0):_vm._e(),(_vm.form.socialMedia.length)?_c('div',{staticClass:"col-lg-6"},_vm._l((_vm.form.socialMedia),function(socialMedia,index){return _c('div',{key:index,staticClass:"d-flex justify-content-around"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"link social media","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("Link Social Media " + (index + 1)),"description":"ex: https://www.linkedin.com/in/user-7212603b","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter link of your social media","trim":""},model:{value:(_vm.form.socialMedia[index].link),callback:function ($$v) {_vm.$set(_vm.form.socialMedia[index], "link", $$v)},expression:"form.socialMedia[index].link"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"col col-sm-3 col-lg-2 col-md-2"},[_c('button',{staticClass:"btn btn-danger",staticStyle:{"height":"40px","margin-top":"27px"},on:{"click":function($event){return _vm.removeFieldSocialMedia(index)}}},[_vm._v(" X ")])])])])}),0):_vm._e()])])])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"next merge d-flex justify-content-end",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.saveChange}},[_vm._v(" Save Change ")])],1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }